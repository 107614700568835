import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RateProps } from "interfaces/types";

export const rateApi = createApi({
    reducerPath: "rateApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
    }),
    tagTypes: ["Rates"],
    endpoints: build => ({
        getRate: build.query<RateProps, any>({
            query(id) {
                return {
                    url: `/admin/exchange-rates/ngn`,
                };
            },
        }),
    }),
});

export const { useGetRateQuery } = rateApi;
