import { RedeemVoucherAPI, BuyVoucherPost } from "./../../interfaces/types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface Post {
    id: number;
    name: string;
}

export interface RedeemVoucher {
    code: number;
    accountNumber: string;
    bank: string;
}

export const voucherApi = createApi({
    reducerPath: "voucherApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
    }),
    tagTypes: ["Vouchers"],
    endpoints: build => ({
        buyVoucher: build.mutation<BuyVoucherPost, Partial<BuyVoucherPost>>({
            query(body) {
                return {
                    url: `/transactions/generate-voucher-reloadit`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["Vouchers"],
        }),

        redeemVoucher: build.mutation<
            RedeemVoucherAPI,
            Partial<RedeemVoucherAPI>
        >({
            query(body) {
                return {
                    url: `/transaction/redeem-voucher-as-guest`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["Vouchers"],
        }),
    }),
});

export const { useBuyVoucherMutation, useRedeemVoucherMutation } = voucherApi;
